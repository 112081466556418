import Amplify, { Auth } from "aws-amplify";

export default function setupAmplify() {
  Amplify.configure({
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {},
    API: {
      endpoints: [
        {
          name: "API",
          endpoint: process.env.REACT_APP_API_URL,
          custom_header: async () => {
            try {
              return {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
              };
            } catch (e) {
              return {};
            }
          },
        },
      ],
    },
  });
}
