import React, { FC, createContext, useContext } from "react";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

type Props = {
  selectedLang: "en" | "fr" | "de" | "lu";
};

type ContextProps = {
  userLanguage?: string;
};

const initialState: Props = {
  selectedLang: "en",
};

function getConfig(userLanguage?: string): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (userLanguage) {
    console.log(`Using user language ${userLanguage}`);
    return {
      selectedLang: userLanguage,
    } as Props;
  }
  if (ls) {
    try {
      return JSON.parse(ls) as Props;
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export function setLanguage(lang: string = "en") {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

export function setInitialLanguage(lang: string = "en") {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
}

const I18nContext = createContext<Props>(initialState);

const useLang = () => {
  return useContext(I18nContext).selectedLang;
};

const MetronicI18nProvider: FC<ContextProps> = ({ children, userLanguage }) => {
  const lang = getConfig(userLanguage);
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export { MetronicI18nProvider, useLang };
