import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useUsers } from "../../../../../hooks/use-users";
import { FormattedMessage, useIntl } from "react-intl";
import { PhoneFormInput } from "../../../../../components/PhoneFormInput";
import { FileUploadWidget } from "../../../../../components/FileUploadWidget";
import { useParams } from "react-router-dom";

const ProfileDetails: React.FC = () => {
  const intl = useIntl();
  const profileDetailsSchema = Yup.object().shape({
    // name: Yup.string().required().label("GLOBAL.NAME"),
    // email: Yup.string().required().label("GLOBAL.EMAIL"),
    // mobile: Yup.string().label("GLOBAL.MOBILE"),
    // language: Yup.string().required().label("GLOBAL.LANGUAGE"),
  });
  const { selectedUser, createUser, updateUser, isLoading, invalidEmail } = useUsers();
  useEffect(() => {
    console.log("Profile:", selectedUser);
  }, [selectedUser]);

  const formRef = useRef<FormikProps<any>>(null);
  useEffect(() => {
    if (invalidEmail && formRef.current) {
      formRef.current.setErrors({
        ...formRef.current.errors,
        email: intl.formatMessage({ id: "VALIDATION.EMAIL_EXIST" }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidEmail]);

  const { userId } = useParams();
  const submitProfile = (values: any) => {
    if (selectedUser?.id) {
      updateUser({ ...selectedUser, ...values }).then();
    } else {
      createUser(values).then();
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            <FormattedMessage
              id={
                userId === "me"
                  ? "GLOBAL.ACCOUNT_SETTING"
                  : selectedUser
                  ? "USERS.PROFILE_DETAILS"
                  : "USERS.NEW_MEMBER"
              }
            />
          </h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: "",
            email: "",
            mobile: "",
            language: "",
            appLanguage: selectedUser?.appLanguage || "en",
            logoUrl: "",
            ...selectedUser,
          }}
          validationSchema={profileDetailsSchema}
          onSubmit={submitProfile}
          innerRef={formRef}
        >
          {() => (
            <Form noValidate>
              <div className='card-body border-top p-9'>
                {selectedUser?.id && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <FormattedMessage id={"GLOBAL.LOGO"} />
                    </label>
                    <div className='col-lg-8'>
                      <FileUploadWidget
                        selectedUser={selectedUser}
                        logoUrl={selectedUser?.logoUrl}
                      />
                    </div>
                  </div>
                )}

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id={"GLOBAL.NAME"} />
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <Field
                      name={"name"}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='kodehyve'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={"name"} />
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id={"GLOBAL.EMAIL"} />
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <Field
                      name={"email"}
                      type='email'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='example@kodehyve.com'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={"email"} />
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>
                      <FormattedMessage id={"GLOBAL.MOBILE"} />
                    </span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <Field
                      name={"mobile"}
                      component={PhoneFormInput}
                      classes={"form-control form-control-lg form-control-solid"}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='mobile' />
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Language</label>
                  <div className='col-lg-8 fv-row'>
                    <Field
                      component={"select"}
                      name={"language"}
                      className='form-select form-select-solid form-select-lg'
                    >
                      <option value=''>
                        {intl.formatMessage({ id: "GLOBAL.SELECT_LANGUAGE" })}
                      </option>
                      <option value='en'>English</option>
                      <option value='fr'>Français</option>
                      <option value='de'>Deutsch</option>
                      <option value='lu'>Lëtzebuergesch</option>
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='language' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={isLoading}>
                  {!isLoading && selectedUser && <FormattedMessage id={"GLOBAL.SAVE"} />}
                  {!isLoading && !selectedUser && <FormattedMessage id={"GLOBAL.CREATE"} />}
                  {isLoading && (
                    <span className='indicator-progress' style={{ display: "block" }}>
                      <FormattedMessage id={"GLOBAL.PLEASE_WAIT"} />{" "}
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { ProfileDetails };
