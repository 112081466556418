import { useUsers } from "../../../../../hooks/use-users";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import React from "react";

type params = {
  openDialog: boolean;
  setOpenDialog: Function;
  type: string;
  action: Function;
};
export const UserActionDialog: React.FC<params> = ({ openDialog, setOpenDialog, action, type }) => {
  const { isLoading, selectedUser } = useUsers();
  return (
    <Modal show={openDialog} centered backdrop='static'>
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg'>
          <FormattedMessage id={`USERS.${type}.TITLE`} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          <FormattedMessage id={!isLoading ? `USERS.${type}.CONFIRM` : "GLOBAL.LOADING"} />
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type='button'
            onClick={() => setOpenDialog(false)}
            className='btn btn-light btn-elevate'
          >
            <FormattedMessage id='GLOBAL.CANCEL' />
          </button>
          <button
            type='button'
            onClick={() => action(selectedUser.id)}
            className='ms-2 btn btn-delete btn-danger btn-elevate'
            disabled={isLoading}
          >
            <FormattedMessage id={"GLOBAL.CONFIRM"} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
