import React, { FC, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SignLayout } from "./components/SignLayout";
import { PdfViewer } from "./components/PdfViewer";
import { FormattedMessage } from "react-intl";
import { OtpModal } from "./OtpModal";
import { SignatureInformationModal } from "./SignatureInformationModal";
import { KTSVG } from "../../../_metronic/helpers";
import { useSign } from "../../hooks/use-sign";
import { Utils } from "../../utils/utils";

const SignPage: FC = () => {
  const { selectedSignature, fetchSignatureById, loadings, setSignStepState, signStepState } =
    useSign();
  const { signatureId } = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    fetchSignatureById(signatureId, searchParams.get("key") || "").then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureId, searchParams]);

  return (
    <>
      <SignatureInformationModal />
      <OtpModal />
      <SignLayout
        signatureTitle={selectedSignature?.signatureTitle ?? ""}
        footer={
          selectedSignature &&
          signStepState !== "SIGNED" && (
            <div className='d-flex justify-content-center'>
              <button
                className='btn btn-md px-10 btn-primary'
                onClick={() => setSignStepState("INFO")}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' />
                <FormattedMessage id={"SIGN.ACTION"} />
              </button>
            </div>
          )
        }
      >
        {loadings.has("fetchSignature") ? (
          <div className='d-flex justify-content-center align-items-center flex-grow-1'>
            <div className='spinner-border spinner-primary spinner-border-lg' />
          </div>
        ) : (
          <>
            {selectedSignature ? (
              signStepState !== "SIGNED" ? (
                <div className={"w-100 h-100 position-absolute"}>
                  <PdfViewer
                    fileUrl={selectedSignature.documentUrl}
                    // sealPositionCoordinates={{ x: 0, y: 0 }}
                  />
                </div>
              ) : (
                <div className={"d-flex flex-column align-items-center"}>
                  <KTSVG
                    className={"svg-icon-success mb-4"}
                    svgClassName={"h-100px w-100px"}
                    path={"/media/icons/duotune/files/fil025.svg"}
                  />
                  <h2 className={"mb-2"}>
                    <FormattedMessage id={"SIGN.DOCUMENT_SIGN_SUCCESS"} />
                  </h2>
                  <div className={"mb-8"}>
                    <FormattedMessage
                      id={"SIGNATURE.EMAIL_SENT"}
                      values={{
                        b: (chunks: any) => <b>{chunks}</b>,
                        email: selectedSignature.signatoryEmail,
                      }}
                    />
                  </div>
                  {selectedSignature.fileUrl && (
                    <button
                      className={"btn btn-lg btn-success"}
                      onClick={() =>
                        Utils.downloadFile(selectedSignature.fileUrl, selectedSignature.fileName)
                      }
                    >
                      <FormattedMessage id={"GLOBAL.DOWNLOAD"} />
                    </button>
                  )}
                </div>
              )
            ) : (
              <h2>
                <FormattedMessage id={"SIGN.NOTHING_TO_SIGN"} />
              </h2>
            )}
          </>
        )}
      </SignLayout>
    </>
  );
};

export { SignPage };
