/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { isAdmin } from "../../utils/authUtils";
import { useAuth } from "../../hooks/use-auth";
import { UserDashboard } from "./UserDashboard";
import { AdminDashboard } from "./AdminDashboard";

const DashboardPage: FC = () => {
  const auth = useAuth();
  return <>{isAdmin(auth) ? <AdminDashboard /> : <UserDashboard />}</>;
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: "GLOBAL.DASHBOARD" })}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
