import type { FC, ReactNode } from "react";
import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import { DocumentType } from "../modules/signatures/components/DocumentHelper";

type Pdf = any;

export enum SignatureLoading {
  createPdf = "createPdf",
}

export interface PdfContextValues {
  pdfPageCount: number;
  pdfPreview: Pdf;
  createPdf: (params: any, file: any, b64: any) => Promise<void>;
  updatePdf: (params: any) => Promise<void>;
  sealPreview: any;
  setSealImage: (sealPreview: any) => void;
  initialPdf: any;
  setPdf: (pdf: any) => void;
  initialPdfPrefilled: any;
  isPdfLoading: boolean;
  setIsPdfLoading: (val: boolean) => void;
  setSealCoordinates: (sealPreview: any) => void;
  sealPositionCoordinates: any;
}

interface SignaturesProviderProps {
  children?: ReactNode;
}

const pdf: any = "";

export const PdfContext = createContext<PdfContextValues>({
  pdfPageCount: 0,
  pdfPreview: pdf,
  createPdf: () => Promise.resolve(),
  updatePdf: () => Promise.resolve(),
  sealPreview: pdf,
  setSealImage: () => Promise.resolve(),
  initialPdf: undefined,
  setPdf: () => Promise.resolve(),
  initialPdfPrefilled: pdf,
  isPdfLoading: false,
  setIsPdfLoading: () => undefined,
  setSealCoordinates: () => Promise.resolve(),
  sealPositionCoordinates: undefined,
});

export const PdfProvider: FC<SignaturesProviderProps> = (props) => {
  const { children } = props;
  const [pdfPreview, setPdfPreview] = useState<any>(pdf);
  const [documentId, setDocumentId] = useState<any>("");
  const [pdfPageCount, setPdfPageCount] = useState<number>(0);
  const [sealPreview, setSealPreview] = useState<any>(undefined);
  const [initialPdf, setInitialPdf] = useState<any>(pdf);
  const [initialPdfPrefilled, setInitialPdfPrefilled] = useState<any>(pdf);
  const [isPdfLoading, setIsLoading] = useState<boolean>(false);
  const [sealPositionCoordinates, setSealPositionCoordinates] = useState<any>(undefined);

  const createPdf = async (signature: any, file: File, b64: any) => {
    console.log("create pdf input vals", signature);

    API.post("API", "/pdf", { body: signature }).then((response) => {
      if (signature.documentType === DocumentType.Template) {
        const fileBase64 = "data:application/pdf;base64," + response?.pdfBase64;
        setDocumentId(response.documentId);
        setPdf(fileBase64);
        setInitialPdf(fileBase64);
        setPdfPageCount(response.pdfPageCount);
        setIsPdfLoading(false);
      } else {
        fetch(response.url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": "",
          },
        }).then();
      }
    });
  };

  const updatePdf = async (signature: any) => {
    setIsPdfLoading(true);
    signature.documentId = documentId;
    signature.fileBase64 = initialPdf;
    signature.mode = "UPDATE";

    API.post("API", "/pdf", { body: signature }).then((response) => {
      const fileBase64 = response?.pdfBase64
        ? "data:application/pdf;base64," + response?.pdfBase64
        : undefined;

      if (DocumentType.Template) {
        setPdfPageCount(response.pdfPageCount);
        setPdf(fileBase64);
        setInitialPdfPrefilled(fileBase64);
      }

      setIsPdfLoading(false);
    });
  };

  const setSealImage = (image: any) => {
    setSealPreview(image);
  };

  const setPdf = (pdf: any) => {
    if (typeof pdf === "string") {
      setPdf(undefined);
    }

    if (pdf) {
      setPdfPreview(pdf);
    }
  };

  const setIsPdfLoading = (val: boolean) => {
    setIsLoading(val);
  };

  const setSealCoordinates = (val: any) => {
    setSealPositionCoordinates(val);
  };

  return (
    <PdfContext.Provider
      value={{
        pdfPageCount,
        pdfPreview,
        createPdf,
        updatePdf,
        sealPreview,
        setSealImage,
        initialPdf,
        setPdf,
        initialPdfPrefilled,
        isPdfLoading,
        setIsPdfLoading,
        setSealCoordinates,
        sealPositionCoordinates,
      }}
    >
      {children}
    </PdfContext.Provider>
  );
};

PdfProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PdfsConsumer = PdfContext.Consumer;
