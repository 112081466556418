/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useUsers } from "../../../../../hooks/use-users";
import { FormattedMessage } from "react-intl";
import { UserActionDialog } from "../UserActionDialog/UserActionDialog";

import { useFormik } from "formik";

interface Params {
  type: string;
  setActionDialog: Function;
  actionDialog: boolean;
  executeAction: Function;
}

const ActionsCard: React.FC<Params> = ({ type, setActionDialog, actionDialog, executeAction }) => {
  const { isLoading } = useUsers();
  const formik = useFormik<any>({
    initialValues: {},
    onSubmit: (values) => {
      if (values.confirm) {
        setActionDialog(true);
        formik.resetForm({ values: {} });
      }
    },
  });
  return (
    <>
      <UserActionDialog
        openDialog={actionDialog}
        type={type}
        setOpenDialog={setActionDialog}
        action={executeAction}
      />
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_deactivate'
          aria-expanded='true'
          aria-controls='kt_account_deactivate'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              <FormattedMessage id={`USERS.${type}.TITLE`} />
            </h3>
          </div>
        </div>

        <div id='kt_account_deactivate' className='collapse show'>
          <form onSubmit={formik.handleSubmit} id='kt_account_deactivate_form' className='form'>
            <div className='card-body border-top p-9'>
              <div className='form-check form-check-solid fv-row'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={!!formik.values.confirm}
                  {...formik.getFieldProps("confirm")}
                />

                <label className='form-check-label fw-bold ps-2 fs-6' htmlFor='deactivate'>
                  <FormattedMessage id={`USERS.${type}.DESCRIPTION`} />
                </label>
              </div>

              {formik.touched.confirm && formik.errors.confirm && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.confirm}</div>
                </div>
              )}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                id='kt_account_deactivate_account_submit'
                type='submit'
                className='btn btn-info fw-bold'
                disabled={!formik.values.confirm}
              >
                {!isLoading ? (
                  <FormattedMessage id={`USERS.${type}.TITLE`} />
                ) : (
                  <span className='indicator-progress' style={{ display: "block" }}>
                    <FormattedMessage id={"GLOBAL.PLEASE_WAIT"} />
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { ActionsCard };
