import axios from "axios";

export class Utils {
  static downloadFile = (fileUrl: string, fileName: string) => {
    axios({
      url: fileUrl,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  };
}
export const incrementStatusCounter = (totalCount: any, counterSignatureRequest: any) => {
  Object.keys(totalCount).forEach(
    (keyCountStatus) =>
      (totalCount[keyCountStatus] += Object.keys(
        counterSignatureRequest?.[keyCountStatus] ?? {}
      ).reduce((total, key) => total + (counterSignatureRequest[keyCountStatus][key] ?? 0), 0))
  );
  return totalCount;
};
