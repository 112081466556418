export const SNACKBAR_MESSAGE = {
  DANGER: {
    FETCH_USERS: {
      type: "danger",
      body: "SNACKBAR.DANGER.FETCH_USERS",
    },
    FETCH_USER: {
      type: "danger",
      body: "SNACKBAR.DANGER.FETCH_USER",
    },
    CREATE_USER: {
      type: "danger",
      body: "SNACKBAR.DANGER.CREATE_USER",
    },
    EMAIL_EXIST: {
      type: "danger",
      body: "SNACKBAR.DANGER.EMAIL_EXIST",
    },
    UPDATE_USER: {
      type: "danger",
      body: "SNACKBAR.DANGER.UPDATE_USER",
    },
    FETCH_SIGNATURE: {
      type: "danger",
      body: "SNACKBAR.DANGER.FETCH_SIGNATURE",
    },
    SEND_CODE: {
      type: "danger",
      body: "SNACKBAR.DANGER.SEND_CODE",
    },
    VALIDATE_SIGN: {
      type: "danger",
      body: "SNACKBAR.DANGER.VALIDATE_SIGN",
    },
    FETCH_SIGNATURES: {
      type: "danger",
      body: "SNACKBAR.DANGER.FETCH_SIGNATURES",
    },
    CREATE_SIGNATURE: {
      type: "danger",
      body: "SNACKBAR.DANGER.CREATE_SIGNATURE",
    },
    UPDATE_SIGNATURE: {
      type: "danger",
      body: "SNACKBAR.DANGER.UPDATE_SIGNATURE",
    },
    CANCEL_SIGNATURE: {
      type: "danger",
      body: "SNACKBAR.DANGER.CANCEL_SIGNATURE",
    },
    SEND_REMINDER: {
      type: "danger",
      body: "SNACKBAR.DANGER.SEND_REMINDER",
    },
    DOWNLOAD_FILE: {
      type: "danger",
      body: "SNACKBAR.DANGER.DOWNLOAD_FILE",
    },
    FETCH_SIGNATURE_DETAILS: {
      type: "danger",
      body: "SNACKBAR.DANGER.FETCH_SIGNATURE_DETAILS",
    },
    DELETE_USER: {
      type: "danger",
      body: "SNACKBAR.DANGER.DELETE_USER",
    },
    CREATE_USER_ACCESS: {
      type: "danger",
      body: "SNACKBAR.DANGER.CREATE_USER_ACCESS",
    },
    REMOVE_USER_ACCESS: {
      type: "danger",
      body: "SNACKBAR.DANGER.REMOVE_USER_ACCESS",
    },
    DASHBOARD: {
      type: "danger",
      body: "SNACKBAR.DANGER.DASHBOARD",
    },
  },
  SUCCESS: {
    CREATE_USER: {
      type: "success",
      body: "SNACKBAR.SUCCESS.CREATE_USER",
    },
    UPDATE_USER: {
      type: "success",
      body: "SNACKBAR.SUCCESS.UPDATE_USER",
    },
    VALIDATE_SIGN: {
      type: "success",
      body: "SNACKBAR.SUCCESS.VALIDATE_SIGN",
    },
    CREATE_SIGNATURE: {
      type: "success",
      body: "SNACKBAR.SUCCESS.CREATE_SIGNATURE",
    },
    CANCEL_SIGNATURE: {
      type: "success",
      body: "SNACKBAR.SUCCESS.CANCEL_SIGNATURE",
    },
    SEND_REMINDER: {
      type: "success",
      body: "SNACKBAR.SUCCESS.SEND_REMINDER",
    },
    DELETE_USER: {
      type: "success",
      body: "SNACKBAR.SUCCESS.DELETE_USER",
    },
    CREATE_USER_ACCESS: {
      type: "success",
      body: "SNACKBAR.SUCCESS.CREATE_USER_ACCESS",
    },
    REMOVE_USER_ACCESS: {
      type: "success",
      body: "SNACKBAR.SUCCESS.REMOVE_USER_ACCESS",
    },
  },
  INFO: {
    SEND_CODE: {
      type: "info",
      body: "SNACKBAR.INFO.SEND_CODE",
    },
  },
};
