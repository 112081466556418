import { Toast, ToastContainer } from "react-bootstrap";
import { KTSVG } from "../../_metronic/helpers";
import { useIntl } from "react-intl";
import { useSnackbars } from "../hooks/use-snackbars";
import React, { useEffect } from "react";
import "./SnackbarsDialog.scss";

export const SnackbarsDialog = () => {
  const { snackbars, removeSnackbar } = useSnackbars();
  const intl = useIntl();
  useEffect(() => {
    console.log(snackbars);
  }, [snackbars]);

  return (
    <div id={"snackbars"}>
      <ToastContainer>
        {snackbars &&
          snackbars.map((snackbar) => (
            <Toast
              key={snackbar.id}
              className={"mb-4"}
              autohide
              onClose={() => removeSnackbar(snackbar.id)}
            >
              <Toast.Header className={"px-4 py-2 justify-content-between"}>
                <div>
                  {snackbar.content.type === "success" && (
                    <>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr085.svg'
                        className='svg-icon-2 svg-icon-lg-1 svg-icon-success'
                      />
                      <span className={"font-weight-bold mr-auto text-success ms-4"}>
                        {intl.formatMessage({ id: snackbar.content.body })}
                      </span>
                    </>
                  )}
                  {snackbar.content.type === "danger" && (
                    <>
                      <KTSVG
                        path='/media/svg/icons/Code/Warning-2.svg'
                        className='svg-icon-2 svg-icon-lg-1 svg-icon-danger'
                      />
                      <span className={"font-weight-bold mr-auto text-danger ms-4"}>
                        {intl.formatMessage({ id: snackbar.content.body })}
                      </span>
                    </>
                  )}
                  {snackbar.content.type === "info" && (
                    <>
                      <KTSVG
                        path='/media/svg/icons/Code/Info-circle.svg'
                        className='svg-icon-2 svg-icon-lg-1 svg-icon-info'
                      />
                      <span className={"font-weight-bold mr-auto text-info ms-4"}>
                        {intl.formatMessage({ id: snackbar.content.body })}
                      </span>
                    </>
                  )}
                </div>
              </Toast.Header>
            </Toast>
          ))}
      </ToastContainer>
    </div>
  );
};
