import { useDropzone } from "react-dropzone";
import React, { FC, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useUsers } from "../hooks/use-users";
import { FormattedMessage } from "react-intl";
import { User } from "../../types/user";
import Jimp from "jimp";

type Props = { selectedUser: User; logoUrl: string };
export const FileUploadWidget: FC<Props> = ({ selectedUser }) => {
  const [loading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone();
  const { setUserAvatar, updateUser } = useUsers();

  const [sealPreview, setSealPreview] = useState<any>(undefined);

  const [fileUpload, setFileUpload] = useState<any>(undefined);

  const upload = async (e: any) => {
    const file = e.dataTransfer?.files
      ? e.dataTransfer.files[0]
      : e.target?.files
      ? e.target.files[0]
      : null;

    if (file) {
      setFileUpload(file);
    }
  };

  useEffect(() => {
    const getLogoCropped = async (file: any) => {
      const logo = await Jimp.read(file);

      const logoWidth = logo.bitmap.width;
      const logoHeight = logo.bitmap.height;

      const aspectRatio = logoWidth / logoHeight;

      // let paddingLeft = 260;
      let paddingTop = 10; //default 10 pixel;

      // const sealTemplate = await Jimp.read(toAbsoluteUrl("/media/templates/seal_template.png"));

      let widthT = 0;
      let heightT = 0;
      if (logoWidth > logoHeight) {
        widthT = 210;
        heightT = widthT / aspectRatio;

        // paddingTop = (sealTemplate.bitmap.height - heightT) / 2;
      } else {
        heightT = 200 - paddingTop;
        widthT = 200 * aspectRatio;
        // paddingLeft = 260 + (sealTemplate.bitmap.width - 260 - widthT) / 2;
      }

      await logo.resize(widthT, heightT);

      return await logo.getBufferAsync("image/png");
    };

    const uploadAvatar = async (file: any) => {
      const { urlLogo, urlSealTemplate } = await setUserAvatar({ user: selectedUser, file: file });
      selectedUser.logoUrl = urlLogo;
      setSealPreview(urlSealTemplate);
      await updateUser(selectedUser);
      return selectedUser.logoUrl;
    };

    const getImageBase64 = async () => {
      return await toBase64(fileUpload);
    };

    if (fileUpload) {
      getImageBase64()
        .then((res) =>
          getLogoCropped(res)
            .then((croppedImage) => {
              uploadAvatar(croppedImage).then((res) => console.log(res));
            })
            .catch(console.error)
        )
        .catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUpload]);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <div className={"image-input image-input-outline mb-6 w-100"}>
      <div
        {...getRootProps({ onDrop: (e) => upload(e) })}
        className='image-input-wrapper'
        style={{
          alignSelf: "center",
          maxWidth: "100%",
          width: "auto",
          height: "auto",
          overflow: "hidden",
        }}
      >
        <OverlayTrigger
          overlay={
            <Tooltip id={"change-photos"}>
              <FormattedMessage id='USERS.AVATAR_UPLOAD_ACTION_TOOLTIP' />
            </Tooltip>
          }
        >
          <div>
            <div
              className=' btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute  mt-n7 end-0 '
              data-action='change'
              data-toggle='tooltip'
            >
              <i className='fa fa-upload icon-sm text-muted' />
            </div>
          </div>
        </OverlayTrigger>

        <div className='upload-file-container' style={{ cursor: "pointer", minHeight: "200px" }}>
          <input
            name='file'
            {...getInputProps({ onChange: (e) => upload(e) })}
            accept='.png, .jpg, .jpeg'
            disabled={loading}
          />

          <div
            className='m-2 d-flex justify-content-center align-items-center flex-column'
            style={{
              cursor: "pointer",
              minHeight: "200px",
              backgroundColor: "#F0F1F9",
            }}
          >
            {selectedUser?.logoUrl && (
              <img
                className={"border border-secondary m-3 shadow-sm"}
                style={{ overflow: "hidden", maxHeight: "200px", maxWidth: "100%" }}
                src={selectedUser?.logoUrl + "?" + new Date().getTime()}
                alt=''
              />
            )}
            {sealPreview && <img src={sealPreview + "?" + new Date().getTime()} alt={""} />}

            <div
              className='d-flex justify-content-center align-items-center flex-column'
              style={{ opacity: "0.4" }}
            >
              {!loading ? (
                !selectedUser?.logoUrl && (
                  <>
                    <span className='svg-icon svg-icon-5x svg-icon-'>
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Camera.svg")} />
                    </span>
                    <div className='text-center'>
                      <FormattedMessage id='USERS.AVATAR_UPLOAD_DESCRIPTION' />
                    </div>
                  </>
                )
              ) : (
                <svg className='splash-spinner ml-0' viewBox='0 0 50 50'>
                  <circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='5' />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
