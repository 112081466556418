import { PDFDocument, PDFImage, PDFPage } from "pdf-lib";
import { SealPosition } from "./SealPosition";
import Jimp from "jimp";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

export const getPdfPreview = async (
  pdf: any,
  seal: any,
  sealPosition: SealPosition = SealPosition.BOTTOM_RIGHT,
  sealPageIndex = 0
) => {
  const pdfDoc = await PDFDocument.load(pdf, {
    ignoreEncryption: true,
  });
  const page = pdfDoc.getPage(sealPageIndex);
  const pngImage = await pdfDoc.embedPng(seal);
  const pngDims = pngImage.scale(0.5);

  const sealPositions: any = {
    TOP_LEFT: { x: 0, y: page.getHeight() - pngDims.height },
    TOP_RIGHT: { x: page.getWidth() - pngDims.width, y: page.getHeight() - pngDims.height },
    BOTTOM_RIGHT: { x: page.getWidth() - pngDims.width, y: 0 },
    BOTTOM_LEFT: { x: 0, y: 0 },
  };

  page.drawImage(pngImage, {
    x: sealPositions[sealPosition]?.x,
    y: sealPositions[sealPosition]?.y,
    width: pngDims.width,
    height: pngDims.height,
  });

  const pdfprev = await pdfDoc.saveAsBase64();
  return pdfprev;
};

export const toBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getLogoCropped = async (file: any) => {
  const fileBase64: any = await toBase64(file);

  const logo = await Jimp.read(fileBase64);

  const logoWidth = logo.bitmap.width;
  const logoHeight = logo.bitmap.height;

  const aspectRatio = logoWidth / logoHeight;

  // let paddingLeft = 260;
  let paddingTop = 10; //default 10 pixel;

  // const sealTemplate = await Jimp.read(toAbsoluteUrl("/media/templates/seal_template.png"));

  let widthT = 0;
  let heightT = 0;
  if (logoWidth > logoHeight) {
    widthT = 210;
    heightT = widthT / aspectRatio;

    // paddingTop = (sealTemplate.bitmap.height - heightT) / 2;
  } else {
    heightT = 200 - paddingTop;
    widthT = 200 * aspectRatio;
    // paddingLeft = 260 + (sealTemplate.bitmap.width - 260 - widthT) / 2;
  }

  await logo.resize(widthT, heightT);

  return await logo.getBufferAsync("image/png");
};

export const getFakeSeal = async (logoUrl: any) => {
  const sealTemplate = await Jimp.read(toAbsoluteUrl("/media/templates/seal_template.png"));

  if (!logoUrl) {
    console.warn("no logo found");
    return await sealTemplate.getBase64Async("image/png");
  }

  const logo = await Jimp.read(logoUrl);

  const logoWidth = logo.bitmap.width;
  const logoHeight = logo.bitmap.height;

  const aspectRatio = logoWidth / logoHeight;

  let paddingLeft = 260;
  let paddingTop = 10;

  let widthT = 0;
  let heightT = 0;
  if (logoWidth > logoHeight) {
    widthT = 210;
    heightT = widthT / aspectRatio;

    paddingTop = (sealTemplate.bitmap.height - heightT) / 2;
  } else {
    heightT = 200 - paddingTop;
    widthT = 200 * aspectRatio;
    paddingLeft = 260 + (sealTemplate.bitmap.width - 260 - widthT) / 2;
  }

  await logo.resize(widthT, heightT);
  await sealTemplate.composite(logo, paddingLeft, paddingTop);

  return await sealTemplate.getBase64Async("image/png");
};

export const getSealBackgroundWithLogo = async (file: any, scale = 0.75) => {
  const logo = await Jimp.read(file);

  const logoWidth = logo.bitmap.width;
  const logoHeight = logo.bitmap.height;

  const aspectRatio = logoWidth / logoHeight;

  let paddingLeft = 270;
  let paddingTop = 0;

  const logoTargetWidth = 200;

  const templateWidth = 480;
  const templateHeight = 200;

  let background = new Jimp(templateWidth, templateHeight, "F2ECE5", (err, image) => {
    if (err) throw err;
  });

  let widthT = 0;
  let heightT = 0;
  if (logoWidth > logoHeight) {
    widthT = logoTargetWidth;
    heightT = widthT / aspectRatio;
    paddingTop = (background.bitmap.height - heightT) / 2;
  } else {
    heightT = logoTargetWidth - paddingTop;
    widthT = logoTargetWidth * aspectRatio;
    paddingLeft = paddingLeft + (background.bitmap.width - paddingLeft - widthT) / 2;
  }

  await logo.resize(widthT, heightT);

  const seal = await background.composite(logo, paddingLeft, paddingTop);

  const borderThickness = 1;
  // border
  const fillCrimson = makeIteratorThatFillsWithColor(background, 0x000000ff);
  background.scan(0, 0, templateWidth, borderThickness, fillCrimson);
  background.scan(0, 0, borderThickness, templateHeight, fillCrimson);
  background.scan(templateWidth - borderThickness, 0, borderThickness, templateHeight, fillCrimson);
  background.scan(0, templateHeight - borderThickness, templateWidth, borderThickness, fillCrimson);

  seal.scale(scale);
  return await seal.getBase64Async("image/png");
};

export const getPdfPreviewWithFakeSeal = async (
  pdf: any,
  user: any,
  sealPosition: SealPosition = SealPosition.BOTTOM_RIGHT,
  sealPageIndex: number,
  setSealPositionCoordinates?: any,
  x: number = 0,
  y: number = 0,
  scale: number = 1
) => {
  if (pdf && user) {
    const pdfDoc = await PDFDocument.load(pdf, {
      ignoreEncryption: true,
    });

    const page = pdfDoc.getPage(sealPageIndex);

    const seal = await getFakeSeal(user?.logoUrl);
    // const seal = await getSealBackgroundWithLogo(user);
    // const seal = await getImageWithRectangle();

    const pngImage = await pdfDoc.embedPng(seal);

    const coordinates = getSealPositionCoordinates(page, pngImage, sealPosition, scale / 2, x, y);

    page.drawImage(pngImage, coordinates);

    return { pageCount: pdfDoc.getPages().length ?? 1, pdf: await pdfDoc.saveAsBase64() };
  }
};

export const getSealPositionCoordinates = (
  page: PDFPage,
  sealImage: PDFImage,
  sealPosition: SealPosition,
  scale: number = 1,
  x: number,
  y: number
) => {
  const sealDimensions = sealImage.scale(scale);

  const sealPositions: any = {
    TOP_LEFT: { x: x, y: page.getHeight() - sealDimensions.height - y },
    TOP_RIGHT: {
      x: page.getWidth() - sealDimensions.width - y,
      y: page.getHeight() - sealDimensions.height - y,
    },
    BOTTOM_RIGHT: { x: page.getWidth() - sealDimensions.width, y: -y },
    BOTTOM_LEFT: { x: x, y: -y },
  };
  return {
    x: sealPositions[sealPosition]?.x,
    y: sealPositions[sealPosition]?.y,
    width: sealDimensions.width,
    height: sealDimensions.height,
  };
};

export function _base64ToArrayBuffer(base64: any) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function makeIteratorThatFillsWithColor(image: Jimp, color: any) {
  return function (x: number, y: number, offset: number) {
    image.bitmap.data.writeUInt32BE(color, offset);
  };
}
