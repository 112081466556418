import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { LayoutInit } from "../_metronic/layout/LayoutInit";
import AuthInit from "./modules/auth/redux/AuthInit";
import { UsersProvider } from "./contexts/users-context";
import { SignaturesProvider } from "./contexts/signatures-context";
import { SnackbarsProvider } from "./contexts/snackbars-context";
import { SnackbarsDialog } from "./components/SnackbarsDialog";
import { PdfProvider } from "./contexts/pdf-context";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <SnackbarsProvider>
          <LayoutProvider>
            <UsersProvider>
              <PdfProvider>
              <SignaturesProvider>
                <AuthInit>
                  <Outlet />
                  <SnackbarsDialog />
                  <LayoutInit />
                </AuthInit>
              </SignaturesProvider>
              </PdfProvider>
            </UsersProvider>
          </LayoutProvider>
        </SnackbarsProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
