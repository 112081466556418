import type { FC, ReactNode } from "react";
import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { orderBy } from "lodash";
import { Utils } from "../utils/utils";
import { useSnackbars } from "../hooks/use-snackbars";
import { SNACKBAR_MESSAGE } from "../components/SnackbarsHandler";

type Signatures = any[];
export enum SignatureLoading {
  fetchSignatures = "fetchSignatures",
  fetchSignatureById = "fetchSignatureById",
  fetchSignatureDetailsById = "fetchSignatureDetailsById",
  createSignature = "createSignature",
  updateSignature = "updateSignature",
  cancelSignature = "cancelSignature",
  sendReminder = "sendReminder",
  downloadFile = "downloadFile",
}

export interface SignaturesContextValue {
  signatures: any[];
  selectedSignature?: any;
  fetchSignatures: () => Promise<void>;
  fetchSignatureById: (signatureId: string | undefined) => Promise<void>;
  fetchSignatureDetailsById: (signatureId: string | undefined) => Promise<void>;
  createSignature: (signature: any) => Promise<void>;
  updateSignature: (signature: any) => Promise<void>;
  cancelSignature: (signature: any) => Promise<void>;
  sendReminder: (signature: any) => Promise<void>;
  downloadFile: (signature: any) => Promise<void>;
  loadings: SignatureLoading[];
}

interface SignaturesProviderProps {
  children?: ReactNode;
}

const initialSignatures: Signatures = [];

export const SignaturesContext = createContext<SignaturesContextValue>({
  signatures: initialSignatures,
  selectedSignature: undefined,
  fetchSignatures: () => Promise.resolve(),
  fetchSignatureById: (signatureId) => Promise.resolve(),
  fetchSignatureDetailsById: (signatureId) => Promise.resolve(),
  createSignature: (signature) => Promise.resolve(),
  updateSignature: (signature) => Promise.resolve(),
  cancelSignature: (signature) => Promise.resolve(),
  sendReminder: (signature) => Promise.resolve(),
  downloadFile: (signature) => Promise.resolve(),
  loadings: [],
});

export const SignaturesProvider: FC<SignaturesProviderProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const [signatures, setSignatures] = useState<Signatures>(initialSignatures);
  const [selectedSignature, setSelectedSignature] = useState<any>(undefined);
  const [loadings, setLoadings] = useState<SignatureLoading[]>([]);
  const { createSnackbar } = useSnackbars();

  const fetchSignatures = async () => {
    loading(SignatureLoading.fetchSignatures);
    API.get("API", "/signatures", {})
      .then((response) => {
        setSignatures(orderBy(response, "createdAt", "desc") || []);
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.FETCH_SIGNATURES);
      })
      .finally(() => loadingFinished(SignatureLoading.fetchSignatures));
  };

  const fetchSignatureById = async (signatureId: string | undefined) => {
    setSelectedSignature(undefined);
    if (signatureId && signatureId !== "new") {
      loading(SignatureLoading.fetchSignatureById);
      API.get("API", `/signatures/${signatureId}`, {})
        .then((response) => {
          console.log("response:", response);
          setSelectedSignature(response);
        })
        .catch(() => {
          createSnackbar(SNACKBAR_MESSAGE.DANGER.FETCH_SIGNATURE);
        })
        .finally(() => loadingFinished(SignatureLoading.fetchSignatureById));
    }
  };

  const createSignature = async (signature: any) => {
    loading(SignatureLoading.createSignature);
    API.post("API", "/signatures", { body: signature })
      .then((response) => {
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.CREATE_SIGNATURE);
        setSelectedSignature(response.data);
        navigate(`/signatures/${response.id}`);
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.CREATE_SIGNATURE);
      })
      .finally(() => loadingFinished(SignatureLoading.createSignature));
  };

  const updateSignature = async (signature: any) => {
    loading(SignatureLoading.updateSignature);
    API.post("API", `/signatures/${signature.id}`, { body: signature })
      .then((response) => {
        setSelectedSignature(response);
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.UPDATE_SIGNATURE);
      })
      .finally(() => loadingFinished(SignatureLoading.updateSignature));
  };

  const cancelSignature = async (signature: any) => {
    loading(SignatureLoading.cancelSignature);
    API.del("API", `/signatures/${signature.id}`, {})
      .then((response) => {
        const newSignatures = [...signatures];
        const updatedSignature = newSignatures.find((s) => s.id === signature.id);
        if (updatedSignature) {
          updatedSignature.status = "CANCELLED";
        }
        setSignatures(newSignatures);
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.CANCEL_SIGNATURE);
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.CANCEL_SIGNATURE);
      })
      .finally(() => loadingFinished(SignatureLoading.cancelSignature));
  };

  const sendReminder = async (signature: any) => {
    loading(SignatureLoading.sendReminder);
    API.post("API", `/signatures/${signature.id}/reminder`, {})
      .then((response) => {
        const newSignatures = [...signatures];
        const updatedSignature = newSignatures.find((s) => s.id === signature.id);
        if (updatedSignature) {
          updatedSignature.nextReminderAvailability = response.nextReminderAvailability;
        }
        setSignatures(newSignatures);
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.SEND_REMINDER);
      })
      .catch((err) => {
        console.log(err.data);
        createSnackbar(SNACKBAR_MESSAGE.DANGER.SEND_REMINDER);
      })
      .finally(() => loadingFinished(SignatureLoading.sendReminder));
  };

  const downloadFile = async (signature: any) => {
    loading(SignatureLoading.downloadFile);
    API.get("API", `/signatures/${signature.id}/download`, {})
      .then((response) => {
        Utils.downloadFile(response.documentUrl, "document.pdf");
      })
      .catch(() => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.DOWNLOAD_FILE);
      })
      .finally(() => loadingFinished(SignatureLoading.downloadFile));
  };

  const fetchSignatureDetailsById = async (signatureId: string | undefined) => {
    setSelectedSignature(undefined);
    if (signatureId && signatureId !== "new") {
      loading(SignatureLoading.fetchSignatureDetailsById);
      API.get("API", `/signatures/${signatureId}/details`, {})
        .then((response) => {
          console.log("response:", response);
          setSelectedSignature(response);
        })
        .catch(() => {
          createSnackbar(SNACKBAR_MESSAGE.DANGER.FETCH_SIGNATURE_DETAILS);
        })
        .finally(() => loadingFinished(SignatureLoading.fetchSignatureDetailsById));
    }
  };

  const loading = (key: SignatureLoading) => {
    setLoadings([...loadings, key]);
  };

  const loadingFinished = (key: SignatureLoading) => {
    const newLoadings = [...loadings];
    let index = newLoadings.indexOf(key);
    if (index > -1) {
      newLoadings.splice(index, 1);
    }
    setLoadings(newLoadings);
  };

  return (
    <SignaturesContext.Provider
      value={{
        signatures,
        selectedSignature,
        fetchSignatures,
        fetchSignatureById,
        fetchSignatureDetailsById,
        createSignature,
        updateSignature,
        cancelSignature,
        downloadFile,
        sendReminder,
        loadings,
      }}
    >
      {children}
    </SignaturesContext.Provider>
  );
};

SignaturesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SignaturesConsumer = SignaturesContext.Consumer;
