import { ProgressBar, SimpleCard } from "../../components/widget";
import React, { useEffect } from "react";
import { useUsers } from "../../hooks/use-users";
import { FormattedMessage } from "react-intl";
import { incrementStatusCounter } from "../../utils/utils";
export const AdminDashboard = () => {
  const { fetchUsers, users } = useUsers();
  useEffect(() => {
    fetchUsers().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usersStatistic = (() => {
    let totalCount: any = {
      SIGNED: 0,
      IN_PROGRESS: 0,
      CANCEL: 0,
    };
    for (const user of users) {
      totalCount = incrementStatusCounter(totalCount, user?.counterSignatureRequest);
    }
    const totalSignature = totalCount.SIGNED + totalCount.IN_PROGRESS + totalCount.CANCEL;
    return {
      totalCount,
      completionRate:
        totalSignature === 0 ? 0 : Math.round((totalCount.SIGNED / totalSignature) * 100),
    };
  })();
  return (
    <div className={"row gy-5 g-xl-8"}>
      <div className={"col-xxl-6"}>
        <div className={"card card-custom gutter-b"}>
          <div
            className={
              "card-body d-flex align-items-center justify-content-between flex-wrap bg-white"
            }
          >
            <div className={"mr-2"}>
              <h3 className={"font-weight-bolder"}>
                <FormattedMessage id={"GLOBAL.HELLO"} values={{ name: ", Chambre Immobilière" }} />
              </h3>
              <i>
                <div>{new Date().toLocaleDateString()}</div>
              </i>
            </div>
          </div>
        </div>
        <div className={"d-flex justify-content-between"}>
          <div className={"col-12 col-md-7 card-custom gutter-b mt-4"}>
            <div className={"card card-custom gutter-b"}>
              <ProgressBar
                className={"m-4"}
                title={"GLOBAL.COMPLETION_RATE"}
                description={""}
                color={
                  usersStatistic.completionRate > 66
                    ? "success"
                    : usersStatistic.completionRate > 33
                    ? "warning"
                    : "danger"
                }
                progress={`${usersStatistic.completionRate}%`}
              />
            </div>
            <div className={"card card-custom gutter-b mt-4"}>
              <SimpleCard
                chartColor={"info"}
                icon={"/media/icons/duotune/files/fil008.svg"}
                info={{
                  data: `${usersStatistic.totalCount.SIGNED}`,
                  title: "GLOBAL.SIGNATURE_REALISED",
                }}
              />
            </div>
          </div>
          <div className={"col-md-4 card card-custom gutter-b mt-4"}>
            <img
              alt={"dashboard"}
              src={"/media/dashboard/cigdl.jpg"}
              className={"h-100"}
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
      <div className={"col-xxl-6"}>
        <div className={"card-xl-stretch mb-xl-8"}>
          <div className={"card card-custom gutter-b"}>
            <SimpleCard
              chartColor={"success"}
              icon={"/media/icons/duotune/abstract/abs014.svg"}
              info={{
                data: (
                  users.filter((user: { isDisabled: boolean }) => !user.isDisabled).length ?? 0
                ).toString(),
                title: "GLOBAL.ACTIVE_MEMBER",
              }}
            />
          </div>
        </div>
        <div className={"card-xl-stretch mb-xl-8"}>
          <div className={"card card-custom gutter-b"}>
            <SimpleCard
              chartColor={"primary"}
              icon={"/media/icons/duotune/abstract/abs013.svg"}
              info={{
                data: (
                  users.filter((user: { isDisabled: boolean }) => user.isDisabled).length ?? 0
                ).toString(),
                title: "GLOBAL.DEACTIVATED_MEMBER",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
