import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSign } from "../../hooks/use-sign";

export function SignatureInformationModal() {
  const { sendCode, signStepState, setSignStepState } = useSign();
  const validateSignatureInfoModal = () => {
    setSignStepState("OTP");
    sendCode().then();
  };
  return (
    <Modal
      show={signStepState === "INFO"}
      onHide={() => setSignStepState(undefined)}
      aria-labelledby='signature-information-modal'
      centered
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={"SIGN.VALIDATE_IDENTITY_TITLE"} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id={"SIGN.VALIDATE_IDENTITY"} />
      </Modal.Body>
      <Modal.Footer>
        <div className={"text-right"}>
          <button
            data-cy='button-signature-send-otp'
            type='button'
            className='btn btn-primary'
            onClick={validateSignatureInfoModal}
          >
            <FormattedMessage id={"SIGN.SEND_CODE"} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
