import { FC } from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/fr";
import enMessages from "./messages/en.json";
import frMessages from "./messages/fr.json";
import deMessages from "./messages/de.json";
import luMessages from "./messages/lu.json";
import * as frYup from "./yup/fr";
import { setLocale } from "yup";

const flattenMessages = (nestedMessages: any, prefix = "") => {
  return Object.keys(nestedMessages).reduce((messages: any, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

const allMessages = {
  en: flattenMessages(enMessages),
  fr: flattenMessages(frMessages),
  de: flattenMessages(deMessages),
  lu: flattenMessages(luMessages),
};

const I18nProvider: FC = ({ children }) => {
  const locale = useLang();
  switch (locale) {
    case "fr":
      setLocale(frYup);
  }
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
