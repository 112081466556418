import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import React, { FC, useEffect, useState } from "react";

import { renderToolbar } from "./CustomToolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "./styles.scss";
import { SealPosition } from "../../../modules/signatures/components/SealPosition";

//TODO add language support
export const PdfViewer: FC<{
  fileUrl: string;
  sealPreview?: any;
  position?: any;
  sealPageIndex?: number;
  defaultScale?: number;
}> = ({ fileUrl, position = SealPosition.BOTTOM_RIGHT, sealPageIndex, defaultScale = 0 }: any) => {
  const [file, setFile] = useState<any>(fileUrl);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });
  useEffect(() => {
    const typeOf = typeof fileUrl;

    if (
      fileUrl &&
      typeOf === "string" &&
      !fileUrl.startsWith("http") &&
      !fileUrl.startsWith("data:application/pdf;base64,")
    ) {
      setFile("data:application/pdf;base64," + fileUrl);
    } else {
      setFile(fileUrl);
    }
  }, [fileUrl, position, sealPageIndex]);

  return (
    <Worker workerUrl='/pdf.worker.min.js'>
      <Viewer
        fileUrl={file}
        initialPage={sealPageIndex}
        defaultScale={defaultScale}
        plugins={[defaultLayoutPluginInstance]}
        renderLoader={(percentages) => <div />}
      />
    </Worker>
  );
};
