import React, { useEffect } from "react";
import { useUsers } from "../../../../hooks/use-users";
import { ProfileDetails } from "./cards/ProfileDetails";
import { ActionsCard } from "./cards/ActionsCard";
import { useParams } from "react-router-dom";
import { useAuth } from "src/app/hooks/use-auth";
import { isAdmin } from "../../../../utils/authUtils";

const UserEdit: React.FC = () => {
  const {
    selectedUser,
    fetchUserById,
    setOpenDisabledDialog,
    setOpenActiveAccountDialog,
    setOpenDeleteDialog,
    openDeleteDialog,
    openActiveAccountDialog,
    openDisabledDialog,
    deleteUser,
    deleteUserAccess,
    createUserAccess,
  } = useUsers();
  const { userId } = useParams();
  const auth = useAuth();
  useEffect(() => {
    fetchUserById(userId).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <>
      <ProfileDetails />
      {selectedUser?.id && isAdmin(auth) && (
        <>
          {selectedUser.isDisabled ? (
            <>
              <ActionsCard
                type={"ACTIVE_ACCOUNT"}
                setActionDialog={setOpenActiveAccountDialog}
                actionDialog={openActiveAccountDialog}
                executeAction={createUserAccess}
              />
              <ActionsCard
                type={"DELETE"}
                setActionDialog={setOpenDeleteDialog}
                actionDialog={openDeleteDialog}
                executeAction={deleteUser}
              />
            </>
          ) : (
            <ActionsCard
              type={"DISABLED_ACCOUNT"}
              setActionDialog={setOpenDisabledDialog}
              actionDialog={openDisabledDialog}
              executeAction={deleteUserAccess}
            />
          )}
        </>
      )}
    </>
  );
};

export { UserEdit };
