import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { useAuth } from "../../../hooks/use-auth";
import { FormattedMessage } from "react-intl";

const initialValues = {
  username: "",
  password: "",
};

export function Login() {
  const loginSchema = Yup.object().shape({
    username: Yup.string().email().min(3).max(50).required().label("GLOBAL.EMAIL"),
    password: Yup.string().min(3).max(50).required().label("GLOBAL.PASSWORD"),
  });
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const submitLogin = (values: any, { setStatus, setSubmitting }: FormikValues) => {
    setLoading(true);
    setStatus();
    console.log("click login");
    console.log(auth.login);
    auth
      .login(values.username, values.password)
      .then(() => {
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log("err", err);
        setSubmitting(false);
        setStatus(err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={submitLogin}>
      {({ status, isSubmitting, isValid }) => (
        <Form className='form w-100' noValidate id='kt_login_signin_form'>
          {status && (
            <div className='mb-10 alert alert-danger'>
              <FormattedMessage id={"AUTH.INVALID_LOGIN"} />
            </div>
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              <FormattedMessage id={"GLOBAL.EMAIL"} />
            </label>
            <Field
              name={"username"}
              className={clsx("form-control form-control-lg form-control-solid")}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={"username"} />
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                  <FormattedMessage id={"GLOBAL.PASSWORD"} />
                </label>
                {/* end::Label */}
                {/* begin::Link */}
                {/*<Link*/}
                {/*  to='/auth/forgot-password'*/}
                {/*  className='link-primary fs-6 fw-bolder'*/}
                {/*  style={{ marginLeft: "5px" }}*/}
                {/*>*/}
                {/*  <FormattedMessage id={"AUTH.FORGOT_PASSWORD"} />*/}
                {/*</Link>*/}
                {/* end::Link */}
              </div>
            </div>
            <Field
              name={"password"}
              type='password'
              autoComplete='off'
              className={clsx("form-control form-control-lg form-control-solid")}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={"password"} />
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={isSubmitting || !isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id={"AUTH.SIGN_IN"} />
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{ display: "block" }}>
                  <FormattedMessage id={"GLOBAL.PLEASE_WAIT"} />
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </Form>
      )}
    </Formik>
  );
}
